<!--积分配置-->
<template>
    <div class="mainBox">
        <div class=" integralBox table" style="height:calc(100% - 90px);padding:0">
            <div class="flex int_border">
                <div class="flex flex-ai-c">
                    <label for="">
                        积分抵用比例
                        <i class="el-icon-warning" @click="tip(1)"></i>：
                    </label>
                    <el-input v-model="formData.score" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="small"></el-input>
                </div>
            </div>
            <!-- <div class="flex int_border">
                <div class="flex flex-ai-c">
                    <label for="">
                        商品积分配置
                        <i class="el-icon-warning"></i>：
                    </label>
                    <el-input v-model="formData.score" oninput="this.value=this.value.toString().match(/^\d+(?:\.\d{0,2})?/)" placeholder="" size="small"></el-input>
                </div>
            </div> -->

            <el-button type="primary" size="small" style="margin:50px 0 0 230px;display: block;width:300px" @click="submit">确定</el-button>
        </div>
        <!--积分使用规则-->
        <el-dialog :title="title" :visible.sync="dialogFormVisible" :modal-append-to-body="false" :close-on-click-modal="false" width="500px">
            <div v-html="tipContent"></div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                formData:{},
                dialogFormVisible:false,
                tipContent:'',
                title:''
            }
        },
        methods: {
            tip: function(num){
                this.dialogFormVisible = true;
                if (num == 1) {
                    this.title = '积分抵用规则';
                    this.tipContent = '积分使用：' + this.formData.score + '积分1元<br>积分使用的限制：根据商品积分的设置不同，部分商品不能使用积分或只能使用积分抵扣部分金额'
                } 
            },
            submit: function(){

            }
        },
    }
</script>